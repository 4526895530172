/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Head from 'next/head';
import { useRouter } from 'next/router'
import type { NextPage, GetServerSidePropsContext, GetServerSideProps } from "next";
import { type AppType, type AppProps } from "next/app";
import { Archivo, Space_Grotesk } from 'next/font/google'
import React, { createContext, useContext, useEffect, useState } from 'react';
import '../styles/embla.css'
import { api } from "@aimor/utils/api";
import { type AuthChangeEvent, type Session } from '@supabase/supabase-js';
import Footer from "@aimor/components/Footer"
import {Analytics } from "@vercel/analytics/react"
import {createClient} from "@aimor/utils/supabase/component"

import "@aimor/styles/globals.css";

const MyApp = ({ Component, pageProps: { ...pageProps } }: AppProps) => {
  const router = useRouter();


  const changeBg = (router.pathname.includes('userDashboard')||router.pathname.includes('guestDashboard'))
  return (
    <div>
      <Head>
        <title>aimor</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
      </Head>

      {/* <div className= {`${changeBg ?" bg-AIMOR_CREAM " :" bg-white "} md:bg-gradient-to-b  md:from-[#5B45FF] md:to-white flex flex-col flex-grow items-center justify-center md:pt-16 overflow-auto `}> */}
         {/* <AuthProvider> */}
         
            <Component {...pageProps} />
            <Analytics/>
            {/* <Footer></Footer> */}
          {/* </AuthProvider> */}
        {/* </div> */}
      
    </div>
  );
};

export function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {}, // You can pass any required props to the page component here
  };
}


export default api.withTRPC(MyApp);




